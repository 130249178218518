.swiper-pagination > .swiper-pagination-bullet {
  opacity: 1;
  background-color: white !important;
}
.swiper-pagination > .swiper-pagination-bullet-active {
  background-color: #96bf00 !important;
}

.swiper-pagination {
  width: fit-content !important;
  left: 50px !important;
}

@media screen and (max-width: 768px) {
  .swiper-pagination {
    width: fit-content !important;
    left: 20px !important;
  }
}
