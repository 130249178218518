.bg-accordion {
  border-radius: 8px;
  background: linear-gradient(
    22deg,
    rgba(217, 217, 217, 0.3) 8.02%,
    rgba(51, 51, 51, 0.3) 122.23%
  );
}

.border-accordion {
  /* border-radius: 12px; */
  border: 2px solid #333;
  background: #0e0e0e;
  box-shadow: 0px 4px 4px 0px #000;
  background-color: #0e0e0e;
}

.border-accordion-solution {
  /* border-radius: 12px; */
  /* border: 2px solid #333; */
  background: #0e0e0e;
  background-color: #0e0e0e;
}

.text-accordion {
  color: #bee545 !important;
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  font-size: 20px;
  font-weight: 800 !important;
}
